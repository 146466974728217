import {
  makeGetRequest,
  makePostRequest
} from './makeRequest'

class GameAPI {
  getGameAccounts() {
    return makeGetRequest('/game/account/1')
  }

  getCharacters() {
    return makeGetRequest('/game/account/characters')
  }

  getCharacterBazaar(characterId) {
    return makePostRequest(`/game/bazaar/${characterId}`)
  }

  getWarehouse(characterId) {
    return makeGetRequest(`/game/warehouse/${characterId}`)
  }

  getItemsData(ids: number[]) {
    return makePostRequest('/game/data/items', ids)
  }

  getItemsIcons(ids: number[]) {
    return makePostRequest('/game/data/items/icons', ids)
  }

  getItemsNames(ids: number[]) {
    return makePostRequest('/game/data/items/names', ids)
  }

  getMonstersIcons(ids: number[]) {
    return makePostRequest('/game/data/monsters/icons')
  }

  getRankingTypes() {
    return makeGetRequest('/game/ranking')
  }

  getRaidRecords(raidId: number) {
    return makeGetRequest(`/game/ranking/top-raid/${raidId}`)
  }

  getRbbSeasonRecords(season: number) {
    return makeGetRequest(`/game/ranking/top-rbb-ranked/${season}`)
  }

  getRankingOf(rankingType) {
    return makeGetRequest(`/game/ranking/${rankingType}`)
  }

  enableCharacter(characterId: string | number) {
    return makePostRequest(`/characters/swap/enable/${characterId}`)
  }

  disableCharacter(characterId: string | number) {
    return makePostRequest(`/characters/swap/disable/${characterId}`)
  }

  swapCharacter(characterIdToEnable: string | number, characterIdToDisable: string | number) {
    return makePostRequest(`/characters/swap/${characterIdToEnable}/${characterIdToDisable}`)
  }
}

const Game = new GameAPI()

export default Game